function mapper(source, destination) {
  if (typeof (destination) == "undefined" || typeof (destination) != typeof (source)) {
    if (typeof (source) == "object") destination = {};
    if (typeof (source) == "array") destination = [];
    if (typeof (source) == "date") destination = new Date;
    if (typeof (source) == "string") destination = "";
    if (typeof (source) == "number") destination = 0;
  }
  let key;
  for (key in source) {
    destination[key] = source[key];
  }
};

export default mapper
